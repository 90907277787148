<template>
  <div class="coinater">
    <div class="fdleft" id="table_content">
      <!-- <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{ project.name }}</div>
        </div>
      </div> -->
      <div class="fdlcont">
        <div class="fdltitle">能效测评汇总表</div>
        <div class="fdltxt">
          <div class="public" v-if="project.building_type==1">
            <public-table ref="publictable"  />
          </div>
          <div class="public" v-else>
            <livetable ref="livetable"></livetable>
          </div>
        </div> 
      </div>
    </div>
    <div class="fdright">
      <div class="fdtitlee">
        <div class="fdtsec">{{ form.name }}</div>
      </div>
      <div class="fdbacck">
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input
            disabled
            v-model="idea"
            placeholder="请输入内容"
            style="height: 513px; border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group v-model="pass" disabled>
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <el-button type="primary" @click="pre_page">上一页</el-button>
          <el-button
            type="primary"
            :disabled="select_disabled"
            @click="saveProject"
            >保存</el-button
          >
          <el-button type="primary" @click="next_page">下一页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import publicTable from "./iptblecompents/publictable.vue"
import Livetable from "./iptblecompents/livetable.vue"
import { projectTableInfo } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";
import { getStaff } from "../../../../api/user";

export default {
    components: {Livetable, publicTable},
  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      formtable: {},
      form: {
        stars: 1,
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],

      photo: [],

      //冷水热泵机组
      jizu: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],

      project: {},

      id: "",

      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 1,
      staffPage2: 1,
      staffPage3: 1,
      activeStaff: 1,
      idea: "",
      pass: "",

      select_disabled: false,
    };
  },

  mounted() {
    let project_id = this.$route.query.project_id;
    if (project_id && project_id != "") {
      this.id = project_id;
      this.getInfo();
    }
  },

  methods: {
    goBack() {
      this.$router.push({
        path: "./ipImformation?id=" + this.id,
      });
    },

    /**
     * 筛选企业
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getInstitution_my(searchVlaue);
    },

    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },

    //获得员工信息
    getStaffList(val = "") {
      console.log(this.activeStaff);
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 100,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
        }
      });
    },

    getInfo() {
      let params = {
        id: this.id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        let project = {
          name: that.form.name,
          building_area: that.form.building_area,
          build_company: that.form.build_company,
          address: that.form.address,
          weather: that.form.weather,
          design_company: that.form.design_company,
          manger_company: that.form.manger_company,
          work_build: that.form.work_build,
          building_type:that.form.building_type
        };
        that.project = project;
        let checks = res.data.checks;
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 2) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.form.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        if(that.formtable.designStand && that.formtable.designStand!=''){
          that.formtable.designStand = parseInt(that.formtable.designStand);
        }
        let jizu_total = that.form.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;

        if (that.form.status != 15 && that.form.status != 17) {
          that.$nextTick(function () {
            let table = document.getElementById("table_content");
            let inputs = table.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = true;
            }
            that.select_disabled = true;
          });
        }
      });
    },

    //上一页，保存
    pre_page() {
      this.$router.push({
        path: "./ipFormtedail?project_id=" + this.id,
      });
    },

    //下一页
    next_page() {
      this.$router.push({
        path: "/user_mark/ipreporttable?project_id=" + this.id,
      });
    },

    //增加 冷水（热泵）机组
    addjizu(which) {
      if (which == 1) {
        this.jizu.push({});
      } else if (which == 2) {
        this.jizu2.push({});
      } else if (which == 3) {
        this.jizu3.push({});
      } else {
        this.jizu4.push({});
      }
    },

    //图片上传成功
    photo_success(re, file, fileList) {
      console.log(fileList);
      this.photo = fileList.map((item) => {
        return item.response.data;
      });
    },

    //切换市
    cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
        if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
        }
      }
    },

    //保存项目
    saveProject() {
      this.$refs.livetable.saveProject();
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log(this.fileList);
    },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },
  },
};
</script>
<style scoped>
/deep/.intu .el-input{
  width: 355%;
  border:none
}
/deep/.tableform .el-form-item{
    margin-bottom: 10px;
}
/deep/.el-textarea > textarea {
  height: 513px;
}
/deep/ .table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 20px;
        height: 76vh;
        overflow-y: scroll;
      }
      .fdltxt::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;
.fdtsec {
    display: flex;
      flex-direction: row-reverse;
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding:27px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin-bottom: 34px;
      }

      .fdradio {
        margin: 15px 0;
        text-align: center;
      }

      .fdrbtn {
        // margin: 20px 0;
        text-align: center;
      }
    }
  }
}

.engerytable input {
  background-color: white;
}
</style>
