<template>
  <div>
    <div class="tableform">

      <el-form ref="form" :model="projectHead" label-width="180px">

        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目名称：">
                  {{ projectHead.name }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建筑面积（m²）/层数：">
                  {{ projectHead.building_area }}/{{ projectHead.floor }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建设单位：">
                  {{ projectHead.build_company }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="施工单位：">
                  <el-input v-model="projectHead.work_build" :disabled="isView"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目地址：">
                  {{ projectHead.address }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="气候区域：">
                  <span v-if="projectHead.weather == 1">夏热冬冷</span>
                  <span v-if="projectHead.weather == 2">寒冷</span>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="设计单位：">
                  <el-input v-model="projectHead.design_company" :disabled="isView"></el-input>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="监理单位：">
                  <el-input v-model="projectHead.manger_company" :disabled="isView"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form ref="formtable" :model="formtable" label-width="180px">
      <div id="engerytable" class="engerytable">
        <table border="1" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <th colspan="8" scope="col" style="height: 40px">
                &nbsp;测评内容
              </th>
              <th scope="col">&nbsp;测评方法</th>
              <th scope="col">&nbsp;测评结果</th>
            </tr>
            <tr>
              <th rowspan="3" scope="row">基础类&nbsp;</th>
              <td colspan="3" style="background-color:#a3c1dd2d">单位建筑面积供暖能耗（kWh/m2）</td>
              <td colspan="2">
                <textarea type="text" v-model="formtable.heatEnergy" placeholder="——" :disabled="isView"/>
              </td>
              <td colspan="2" rowspan="3" style="background-color:#a3c1dd2d">&nbsp; 相对节能率（%）</td>
              <td rowspan="3">
                <el-select :disabled="isView" v-model="formtable.evaluationWay_heatEnergy" placeholder="软件评估" clearable >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td rowspan="3">
                <textarea type="text" v-model="formtable.evaInstitu_heatEnergy" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color:#a3c1dd2d">单位建筑面积空调能耗（kWh/m2）</td>
              <td colspan="2">
                <textarea type="text" v-model="formtable.airEnergy" placeholder="——" :disabled="isView"/>
              </td>
              <!-- <td>
                   <el-select :disabled="isView" v-model="formtable.evaluationWay_airEnergy" placeholder="选择测评方法" clearable>
                   <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  </el-select>

              </td> -->
              <!-- <td>
                <textarea type="text" v-model="formtable.evaInstitu_airEnergy" placeholder="——" />
              </td> -->
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color:#a3c1dd2d">单位建筑面积全年能耗量（kWh/m² )</td>
              <td colspan="2">
                <textarea type="text" v-model="formtable.buildEnergy" placeholder="——" :disabled="isView"/>
              </td>
              <!-- <td>
                      <el-select :disabled="isView" v-model="formtable.evaluationWay_buildEnergy" placeholder="选择测评方法" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_buildEnergy" placeholder="——"/>
              </td> -->
            </tr>
            <tr>
              <th :rowspan="
                30 + jizu5.length + jizu.length + jizu3.length + jizu6.length
              " scope="row">
                &nbsp;规定项
              </th>
              <th rowspan="5" style="background-color:#a3c1dd2d">&nbsp;围护结构</th>
              <td colspan="2" style="background-color:#a3c1dd2d">外窗气密性</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.wallGas" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_wallGas" placeholder="文件审查" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_wallGas" placeholder="——" :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td colspan="2" style="background-color:#a3c1dd2d">热桥部位（夏热冬冷/寒冷地区）</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.hotPart" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_hotPart" placeholder="文件审查；现场检查"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_hotPart" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color:#a3c1dd2d">门窗保温（夏热冬冷/寒冷地区）</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.doorHeat" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_doorHeat" placeholder="文件审查；现场检查"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_doorHeat" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color:#a3c1dd2d">建筑外窗（夏热冬冷/寒冷地区）</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.wallOpenArea" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_wallOpenArea" placeholder="文件审查；现场检查"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_wallOpenArea" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color:#a3c1dd2d">南向外遮阳</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.outShade" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_outShade" placeholder="文件审查；现场检查"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_outShade" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td :rowspan="
                6 + jizu5.length + jizu.length + jizu3.length + jizu6.length
              " scope="row" style="background-color:#a3c1dd2d">
                &nbsp;冷 热 源 及 空 调 系 统
              </td>
              <td colspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp; 空调冷源</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.coldSource" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_coldSource" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_coldSource" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color:#a3c1dd2d">供暖热源&nbsp;</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.hotSource" placeholder="——" :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_hotSource" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_hotSource" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" :rowspan="jizu5.length + 1" scope="row" style="background-color:#a3c1dd2d">
                房间空气调节器
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(1)">
                    增加 房间空气调节器
                  </el-button>
                </div>
              </td>
              <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
              <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
              <td style="background-color:#a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu5" :key="index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——" :disabled="isView"/>
              </th>
              <td>
                <textarea type="text" v-model="item.unitMeasure" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——"  :disabled="isView"/>
              </td>
              <td v-if="index == 0" :rowspan="jizu5.length">
                <el-select :disabled="isView" v-model="item.evaluationWay" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>

              <td v-if="index == 0" :rowspan="jizu5.length">
                <textarea type="text" v-model="item.evaInstitu" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" :rowspan="jizu.length + 1" scope="row" style="background-color:#a3c1dd2d">
                冷水（热泵）机组
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(2)">
                    增加 冷水（热泵）机组
                  </el-button>
                </div>
              </td>
              <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
              <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
              <td style="background-color:#a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td ></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu" :key="index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——"  :disabled="isView"/>
              </th>
              <td>
                <textarea type="text" v-model="item.unitMeasure" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——"  :disabled="isView"/>
              </td>
              <td v-if="index == 0" :rowspan="jizu.length">
                <el-select :disabled="isView" v-model="item.evaluationWay" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>

              <td v-if="index == 0" :rowspan="jizu.length">
                <textarea type="text" v-model="item.evaInstitu" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td :rowspan="jizu3.length + 1" colspan="2" style="background-color:#a3c1dd2d">
                多联式空调（热泵）机组
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(3)">
                    增加 多联式空调（热泵）机组
                  </el-button>
                </div>
              </td>
              <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td style="background-color:#a3c1dd2d">&nbsp;单机额定制冷量(kW)</td>
              <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
              <td style="background-color:#a3c1dd2d">&nbsp;综合性能系数(IPLV)</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu3" :key="index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——"  :disabled="isView"/>
              </th>
              <td>
                <textarea type="text" v-model="item.unitMeasure" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——" :disabled="isView" />
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——"  :disabled="isView"/>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <el-select :disabled="isView" v-model="item.evaluationWay" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <textarea type="text" v-model="item.evaInstitu" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" :rowspan="jizu6.length + 1" scope="row" style="background-color:#a3c1dd2d">
                转速可控型房间空调器
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(4)">
                    增加转速可控型房间空调器
                  </el-button>
                </div>
              </td>
              <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
              <td style="background-color:#a3c1dd2d">&nbsp; 台数</td>
              <td style="background-color:#a3c1dd2d">&nbsp;能效比（EER）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu6" :key="index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——"  :disabled="isView"/>
              </th>
              <td>
                <textarea type="text" v-model="item.unitMeasure" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——"  :disabled="isView"/>
              </td>
              <td v-if="index == 0" :rowspan="jizu6.length">
                <el-select :disabled="isView" v-model="item.evaluationWay" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu6.length">
                <textarea type="text" v-model="item.evaInstitu" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td rowspan="11" scope="row" style="background-color:#a3c1dd2d">集中冷热源与管网</td>
              <td colspan="2" rowspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp; 锅炉</td>
              <td colspan="2" style="background-color:#a3c1dd2d">类型&nbsp;</td>
              <td colspan="2" style="background-color:#a3c1dd2d">&nbsp; 额定热效率（%）</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                <textarea type="text" v-model="formtable.boilerType" placeholder="——"  :disabled="isView"/>
              </th>
              <td colspan="2">
                <textarea type="text" v-model="formtable.rateEffic" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_rateEffic" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_rateEffic" placeholder="——" :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" rowspan="2" scope="row " style="background-color:#a3c1dd2d">户式燃气炉</td>
              <td colspan="2" style="background-color:#a3c1dd2d">类型&nbsp;</td>
              <td colspan="2" style="background-color:#a3c1dd2d">&nbsp; 额定热效率（%）</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                <textarea type="text" v-model="formtable.houseType" placeholder="——"  :disabled="isView"/>
              </th>
              <td colspan="2">
                <textarea type="text" v-model="formtable.houseEffic" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_houseEffic" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_houseEffic" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">
                集中供暖系统热水循环水泵的耗电输热比
              </td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.focusRatio" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_focusRatio" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_focusRatio" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">热量表</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.heatMeater" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_heatMeater" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_heatMeater" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d"> 分户温控及计量</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.temMeasure" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_temMeasure" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_temMeasure" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">水力平衡&nbsp;</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.waterBalence" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_waterBalence" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_waterBalence" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">自动控制装置</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.autoControl" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_autoControl" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_autoControl" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">自动监测与控制</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.detectSystem" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_detectSystem" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_detectSystem" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">供热量控制</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.heatControl" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_heatControl" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_heatControl" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" rowspan="6" style="background-color:#a3c1dd2d">供暖与空调系统</td>
              <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;不应采用直接电热供暖/冷</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.noDirectHeat" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_noDirectHeat" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_noDirectHeat" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">冷/热水系统的输送能效比</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.waterTransAutio" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_waterTransAutio" placeholder="——"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_waterTransAutio" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">空调冷热源机房的自动控制系统</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.airAutoSystem" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_airAutoSystem" placeholder="——"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_airAutoSystem" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">分时分室控制设施</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.apartMeasure" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_apartMeasure" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_apartMeasure" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">分户热量计量装置</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.apartHeatUnit" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_apartHeatUnit" placeholder="——"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_apartHeatUnit" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">水力平衡措施</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.waterMeasure" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_waterMeasure" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_waterMeasure" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">照明系统</td>
              <td colspan="2" style="background-color:#a3c1dd2d">照度和照明功率密度</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.lightDensity" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_lightDensity" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_lightDensity" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">生活热水系统</td>
              <td colspan="2" style="background-color:#a3c1dd2d">太阳能热水系统</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.gatherHot" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_gatherHot" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_gatherHot" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="14" >选择项&nbsp;</th>
              <td rowspan="4" style="background-color:#a3c1dd2d">&nbsp;可再生能源</td>
              <td style="background-color:#a3c1dd2d">&nbsp;太阳能热水系统</td>
              <td style="background-color:#a3c1dd2d">集热效率&nbsp;</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.heatAuito" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_heatAuito" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_heatAuito" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">可再生能源发电系统&nbsp;</td>
              <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.renewRatio" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_renewRatio" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_renewRatio" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">&nbsp;地源热泵系统</td>
              <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.earthRatio" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_earthRatio" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_earthRatio" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">风冷热泵系统</td>
              <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.windSystem" placeholder="——"  :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_windSystem" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_windSystem" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;自然通风</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.natureWind" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_natureWind" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_natureWind" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;自然采光</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.natureLight" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_natureLight" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_natureLight" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;有效遮阳措施</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.lightMeasure" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_lightMeasure" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_lightMeasure" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;余热作为太阳能热水辅助热源</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.heatAid" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_heatAid" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_heatAid" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;余热作为冬季供暖热源</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.winterHeat" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_winterHeat" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_winterHeat" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;能量回收系统</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.powerBack" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_powerBack" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_powerBack" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">冷热源设备能效等级&nbsp;</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.energyGrade" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_energyGrade" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_energyGrade" placeholder="——" />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;供暖空调负荷预测功能</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.heatFore" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_heatFore" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_heatFore" placeholder="——"   :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;冷冻水出水温度阶段性调整</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.coldTemAdjust" placeholder="——"  :disabled="isView" />
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_coldTemAdjust" placeholder="——"
                  clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_coldTemAdjust" placeholder="——"  :disabled="isView" />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;其他新型节能措施</td>
              <td colspan="4">
                <textarea type="text" v-model="formtable.otherMeasure" placeholder="——"   :disabled="isView"/>
              </td>
              <td>
                <el-select :disabled="isView" v-model="formtable.evaluationWay_otherMeasure" placeholder="——" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option value="7" label="文件审查；现场检查；性能检测"></el-option>
                </el-select>
              </td>
              <td>
                <textarea type="text" v-model="formtable.evaInstitu_otherMeasure" placeholder="——"  :disabled="isView"/>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="10">
                <div class="minyong">
                  <p>1、民用建筑能效测评结论：</p>
                  <p>
                    （1）经软件模拟该项目基础相对节能率为<input type="text" v-model="formtable.relateEnergy"  :disabled="isView" />,(设计标准为
                    <span class="minyong_select">
                        <el-select :disabled="isView" v-model="formtable.designStand">
                          <el-option label="《建筑节能与可再生能源利用通用规范》GB 55015-2021" value="1"></el-option>
                          <el-option label="《公共建筑节能设计标准》DGJ32J96-2010" value="2"></el-option>
                          <el-option label="《公共建筑节能设计标准》GB50189-2015" value="2"></el-option>
                        </el-select>
                      </span>
                    <span> ); </span>
                  </p>
                  <p>
                    （2）经测评，该项目规定项<input type="text" v-model="formtable.strip"  :disabled="isView" />条参评，均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020规定要求；
                  </p>
                  <p>
                    （3）经测评，该项目选择项<input v-model="formtable.selectStrip" type="text"  :disabled="isView" />条参评，加分<input
                      v-model="formtable.grades" type="text" />分；
                  </p>
                  <p>
                    （4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020标准要求，建筑节能率为<input type="text" v-model="formtable.buildEnergyRatio" :disabled="isView"/>，测评合格。
                  </p>
                  <p>2、民用建筑能效标识建议：</p>
                  <p>
                    依据民用建筑能效测评结论，建议该建筑能效标识为
                    {{ project.stars }}星。
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row" colspan="4">测评人员&nbsp;</th>
              <th colspan="4">&nbsp;审核人员</th>
              <th colspan="4">&nbsp;批准人员</th>
            </tr>
            <tr class="table_select">
              <th colspan="4" scope="row">
                <el-select :disabled="isView" v-model="formtable.evaluatUser" v-loadmore="getStaffList"
                  :filter-method="fliterStaff" filterable placeholder="盖章/签字" @focus="selectFocus(1)">
                  <el-option v-for="item in staffList" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.evaluatUser" placeholder="盖章/签字" type="text">-->
              </th>
              <td colspan="4">
                <el-select :disabled="isView" v-model="formtable.auditUser" v-loadmore="getStaffList"
                  :filter-method="fliterStaff" filterable placeholder="盖章/签字" @focus="selectFocus(2)">
                  <el-option v-for="item in staffList2" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.auditUser" placeholder="盖章/签字" type="text">-->
              </td>
              <td colspan="4">
                <el-select :disabled="isView" v-model="formtable.approveUser" v-loadmore="getStaffList"
                  :filter-method="fliterStaff" filterable placeholder="盖章/签字" @focus="selectFocus(3)">
                  <el-option v-for="item in staffList3" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.approveUser" placeholder="盖章/签字" type="text">-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getStaff } from "../../../../../api/user";
import { checkSave, editTableProject, projectTableInfo } from "../../../../../api/project";

export default {

  data() {
    return {
      projectHead: {},
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],
      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],
      jizu3: [],
      jizu4: [],
      jizu5: [],
      jizu6: [],
      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 2,
      staffPage2: 2,
      staffPage3: 2,
      activeStaff: 1,
      edit: true,
      //1是从形式审查调过来的，2是从技术审查跳过来的
      from: 1,
      shencha1: [],

      //是否是查看，false可编辑 true不可编辑
      isView: false,
    }
  },

  mounted() {
    this.project_id = this.$route.query.project_id;
    this.getAllStaffList();
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.getInfo();
  },


  methods: {

    //保存项目
    saveProject() {
      let that = this;
      this.project.jizu = this.jizu;
      this.project.jizu2 = this.jizu2;
      this.project.jizu3 = this.jizu3;
      this.project.jizu4 = this.jizu4;
      this.project.detail = this.formtable;
      this.project.project = this.projectHead;
      let params = this.project;

      params.project.created_at = undefined;
      params.project.updated_at = undefined;
      params.project_id = this.id;
      //保存不改变状态
      params.save = 4;

      params.token = this.$store.state.token;
      if (params.files) {
        for (let i = 0; i < params.files.length; i++) {
          params.files[i].name = params.files[i].file;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$message.success("保存成功")
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获得员工信息
    getStaffList(val = "") {
      console.log(this.activeStaff);
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 1000,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
        }
      });
    },
    check() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        if (that.project.status > 8) {
          that.edit = false;
        }

        let projectHead = {
          name: that.project.name,
          building_area: that.project.building_area,
          build_company: that.project.build_company,
          address: that.project.address,
          weather: that.project.weather,
          design_company: that.project.design_company,
          manger_company: that.project.manger_company,
          work_build: that.project.work_build,
        };
        that.projectHead = projectHead;

        let checks = res.data.checks;
        that.shencha1 = checks.map(item => {
          if (item.type == 2) {
            return item;
          }
        }).filter(d => d);
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 2) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }

        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        if (this.project.status != 1 && this.project.status != 15 && this.project.status != 17 && this.project.status != 21) {
          that.$nextTick(function () {
            let table = document.getElementById("engerytable");
            let inputs = table.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = true;
            }
            this.isView = true;
          });
        }
      });
    },

    pre_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/formDetail?project_id=" + this.project_id,
        });
      });
    },
    next_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/reportTable?project_id=" + this.project_id,
        });
      });
    },
    getAllStaffList() {
      let params = {
        token: this.$store.state.token,
        limit: 1000,
        page: 1,
      };
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          this.staffList = res.data;
          this.staffList2 = res.data;
          this.staffList3 = res.data;
        }
      });
    },
    /**
     * 筛选企业
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getStaffList(searchVlaue);
    },
    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },
  }

}
</script>
<style scoped>
th{
  background-color: #a3c1dd2d;
}
/* td{
  text-align: center;
} */
/deep/ .el-textarea>textarea {
  height: 513px;
}

/deep/ .table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style scoped>
.engerytable>table>tbody>tr>td:nth-child(3) {
  text-align: center;
}

.engerytable>table>tbody>tr>td:nth-child(4) {
  text-align: center;
}

.engerytable>table>tbody>tr>td:nth-child(6) {
  text-align: center;
}

.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

textarea {
  width: 98%;
  height: 40px;
  border: none;
  text-align: center;
  line-height: 40px;
  overflow: visible;
  font-size: 14px;
  word-break: break-all;
  background-color: #f4f4f4f4;
}

textarea::-webkit-scrollbar {
  display: none;
}
</style>
