<template>
  <div>
    <div class="tableform">
      <el-form ref="form" :model="projectHead" label-width="180px">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目名称：">
                  {{ projectHead.name }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建筑面积（m²）/层数：">
                  {{ projectHead.building_area }}/{{ projectHead.floor }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建设单位：">
                  {{ projectHead.build_company }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="施工单位：">
                  <el-input v-model="projectHead.work_build"  :disabled="isView"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目地址：">
                  {{ projectHead.address }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="气候区域：">
                  <span v-if="projectHead.weather == 1">夏热冬冷</span>
                  <span v-if="projectHead.weather == 2">寒冷</span>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="设计单位：">
                  <el-input
                    v-model="projectHead.design_company"
                    :disabled="isView"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="监理单位：">
                  <el-input
                    v-model="projectHead.manger_company"
                    :disabled="isView"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form ref="formtable" :model="formtable" label-width="180px">
      <div id="engerytable" class="engerytable">
        <table border="1" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <th colspan="8" scope="col" style="height: 40px">
                &nbsp;测评内容
              </th>
              <th scope="col">&nbsp;测评方法</th>
              <th scope="col">&nbsp;测评结果</th>
            </tr>
            <tr>
              <th rowspan="3" scope="row">基础类&nbsp;</th>
              <td colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;供暖耗能（kW·h/m²）
              </td>
              <td colspan="2">
                <textarea
                  v-model="formtable.heatEnergy"
                   :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td colspan="2" rowspan="3" style="background-color: #a3c1dd2d">
                &nbsp; 相对节能率（%）
              </td>
              <td rowspan="3">
                <span v-if="formtable.evaluationWay_heatEnergy == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_heatEnergy == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_heatEnergy == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_heatEnergy == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_heatEnergy == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_heatEnergy == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_heatEnergy == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td rowspan="3">
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_heatEnergy"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;空调能耗（kW·h/m²）
              </td>
              <td colspan="2">
                <textarea
                  :disabled="isView"
                  v-model="formtable.airEnergy"
                  type="text"
                  placeholder="——"
                />
              </td>
              <!-- <td><textarea v-model="formtable.evaluationWay_airEnergy" type="text"></td>
                  <td><textarea v-model="formtable.evaInstitu_airEnergy" type="text"></td> -->
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                单位建筑面积全年能耗量（kWh/m² )
              </td>
              <td colspan="2">
                <textarea
                  :disabled="isView"
                  v-model="formtable.buildEnergy"
                  type="text"
                  placeholder="——"
                />
              </td>
              <!-- <td><input v-model="formtable.evaluationWay_buildEnergy" type="text"></td>
                  <td><input v-model="formtable.evaInstitu_buildEnergy" type="text"></td> -->
            </tr>
            <tr>
              <th
                :rowspan="
                  29 + jizu.length + jizu2.length + jizu3.length + jizu4.length
                "
                scope="row"
              >
                &nbsp;规定项
              </th>
              <td rowspan="5" style="background-color: #a3c1dd2d">
                &nbsp;围护结构
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp;外窗、透明幕墙气密性
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.wallGas"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_wallGas == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_wallGas == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_wallGas == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_wallGas == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_wallGas == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_wallGas == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_wallGas == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <el-select
                  v-model="formtable.evaInstitu_wallGas"
                  :disabled="isView"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <textarea v-model="formtable.evaInstitu_wallGas" type="text" placeholder="——" /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" style="background-color: #a3c1dd2d">
                热桥部位&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.hotPart"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <el-select
                  v-model="formtable.evaInstitu_hotPart"
                  :disabled="isView"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <textarea v-model="formtable.evaInstitu_hotPart" type="text" placeholder="——" /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                门窗保温&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.doorHeat"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_doorHeat == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_doorHeat == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_doorHeat == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_doorHeat == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_doorHeat == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_doorHeat == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_doorHeat == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <el-select
                  v-model="formtable.evaInstitu_doorHeat"
                  :disabled="isView"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <textarea v-model="formtable.evaInstitu_doorHeat" type="text" placeholder="——" /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                透明幕墙可开启面积
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.wallOpenArea"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_wallOpenArea == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_wallOpenArea == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_wallOpenArea == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_wallOpenArea == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_wallOpenArea == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_wallOpenArea == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_wallOpenArea == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <el-select
                  v-model="formtable.evaInstitu_wallOpenArea"
                  :disabled="isView"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <textarea v-model="formtable.evaInstitu_wallOpenArea" type="text" placeholder="——" /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                外遮阳&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.outShade"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_outShade == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_outShade == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_outShade == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_outShade == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_outShade == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_outShade == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_outShade == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <el-select
                  v-model="formtable.evaInstitu_wallOpenArea"
                  :disabled="isView"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <textarea v-model="formtable.evaInstitu_wallOpenArea" type="text" placeholder="——" /> -->
              </td>
            </tr>
            <tr>
              <td
                :rowspan="
                  19 + jizu.length + jizu2.length + jizu3.length + jizu4.length
                "
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                &nbsp;冷 热 源 及 空 调 系 统
              </td>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                &nbsp; 空调冷源
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.coldSource"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_coldSource == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_coldSource == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSource == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSource == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSource == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSource == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_coldSource == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_coldSource"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                供暖热源&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.hotSource"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotSource == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotSource == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotSource == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotSource == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotSource == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotSource == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotSource == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_hotSource"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                rowspan="2"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                &nbsp; 锅炉
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                类型&nbsp;
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp; 额定热效率（%）
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2" scope="row">
                <textarea
                  v-model="formtable.boilerType"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td colspan="2">
                <textarea
                  v-model="formtable.rateEffic"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_rateEffic == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_rateEffic == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_rateEffic == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_rateEffic == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_rateEffic == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_rateEffic == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_rateEffic == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_rateEffic"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td
                :rowspan="jizu.length + 1"
                colspan="2"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                冷水（热泵）机组
                <div class="zengjia">
                  <!-- <el-button type="primary" @click="addjizu(1)">
                        增加 冷水（热泵）机组
                      </el-button> -->
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 单机额定制冷量（kW）
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;台数</td>
              <td style="background-color: #a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu" :key="item">
              <td scope="row">
                <textarea
                  v-model="item.unitType"
                  :disabled="isView"
                  placeholder="——"
                  type="text"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitMeasure"
                  :disabled="isView"
                  placeholder="——"
                  type="text"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitNum"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitErr"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td v-if="index == 0" :rowspan="jizu.length">
                <el-select
                  v-model="item.evaluationWay"
                  :disabled="isView"
                  clearable
                  placeholder="——"
                >
                  <el-option label="软件评估" value="1"></el-option>
                  <el-option label="文件审查" value="2"></el-option>
                  <el-option label="现场检查" value="3"></el-option>
                  <el-option label="性能检查" value="4"></el-option>
                  <el-option label="文件审查；现场检查" value="5"></el-option>
                  <el-option label="文件审查；性能检测" value="6"></el-option>
                  <el-option
                    label="文件审查；现场检查；性能检测"
                    value="7"
                  ></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu.length">
                <textarea
                  v-model="item.evaInstitu"
                  :disabled="isView"
                  placeholder="——"
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td
                :rowspan="jizu2.length + 1"
                colspan="2"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                单元式空气调节机、风管送风式和屋顶调节机组
                <div class="zengjia">
                  <!-- <el-button type="primary" @click="addjizu(2)">
                        单元式空气调节机、风管送风式和屋顶调节机组
                      </el-button> -->
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 单机额定制冷量（kW）
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp; 台数</td>
              <td style="background-color: #a3c1dd2d">&nbsp;能效比（EER）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu2" :key="item">
              <td scope="row">
                <textarea
                  v-model="item.unitType"
                  :disabled="isView"
                  placeholder="——"
                  type="text"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitMeasure"
                  placeholder="——"
                  :disabled="isView"
                  type="text"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitNum"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitErr"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td v-if="index == 0" :rowspan="jizu2.length">
                <el-select
                  v-model="item.evaluationWay"
                  clearable
                  placeholder="——"
                  :disabled="isView"
                >
                  <el-option label="软件评估" value="1"></el-option>
                  <el-option label="文件审查" value="2"></el-option>
                  <el-option label="现场检查" value="3"></el-option>
                  <el-option label="性能检查" value="4"></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu2.length">
                <textarea
                  v-model="item.evaInstitu"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
            </tr>
            <!-- <tr>
            <th scope="col" colspan="8" style="height:40px">测评内容&nbsp;</th>
            <th scope="col">测评方法&nbsp;</th>
            <th scope="col">&nbsp;测评结果</th>

          </tr> -->
            <tr>
              <!-- <th scope="row" :rowspan="18+jizu3.length+jizu4.length">&nbsp;规定项</th> -->
              <!-- <td  :rowspan="13+jizu3.length+jizu4.length">冷热源及空调系统</td> -->
              <td
                :rowspan="jizu3.length + 1"
                colspan="2"
                style="background-color: #a3c1dd2d"
              >
                多联式空调（热泵）机组
                <div class="zengjia">
                  <!-- <el-button type="primary" @click="addjizu(3)">
                        增加 多联式空调（热泵）机组
                      </el-button> -->
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp;单机额定制冷量(kW)
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;台数</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp;综合性能系数(IPLV)
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu3" :key="item">
              <td scope="row">
                <textarea
                  v-model="item.unitType"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitMeasure"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitNum"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitErr"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <el-select
                  v-model="item.evaluationWay"
                  clearable
                  placeholder="——"
                  :disabled="isView"
                >
                  <el-option label="软件评估" value="1"></el-option>
                  <el-option label="文件审查" value="2"></el-option>
                  <el-option label="现场检查" value="3"></el-option>
                  <el-option label="性能检查" value="4"></el-option>
                  <el-option label="文件审查；现场检查" value="5"></el-option>
                  <el-option label="文件审查；性能检测" value="6"></el-option>
                  <el-option
                    label="文件审查；现场检查；性能检测"
                    value="7"
                  ></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <textarea
                  v-model="item.evaInstitu"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td
                :rowspan="jizu4.length + 1"
                colspan="2"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                溴化锂吸收式机组
                <div class="zengjia">
                  <!-- <el-button type="primary" @click="addjizu(4)">
                        增加溴化锂吸收式机组
                      </el-button> -->
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 单机额定制冷量(kW)
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp; 台数</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 综合性能系数(IPLV)
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr v-for="(item, index) in jizu4" :key="item">
              <td scope="row">
                <textarea
                  v-model="item.unitType"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitMeasure"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitNum"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td>
                <textarea
                  v-model="item.unitErr"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
              <td v-if="index == 0" :rowspan="jizu4.length">
                <el-select
                  v-model="item.evaluationWay"
                  clearable
                  placeholder="——"
                  :disabled="isView"
                >
                  <el-option label="软件评估" value="1"></el-option>
                  <el-option label="文件审查" value="2"></el-option>
                  <el-option label="现场检查" value="3"></el-option>
                  <el-option label="性能检查" value="4"></el-option>
                  <el-option label="文件审查；现场检查" value="5"></el-option>
                  <el-option label="文件审查；性能检测" value="6"></el-option>
                  <el-option
                    label="文件审查；现场检查；性能检测"
                    value="7"
                  ></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu4.length">
                <textarea
                  v-model="item.evaInstitu"
                  placeholder="——"
                  type="text"
                  :disabled="isView"
                />
              </td>
            </tr>

            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                室内设计计算温度
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.indoorTem"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_heatEnergy"
                  clearable
                  placeholder="——"
                  :disabled="isView"
                >
                  <el-option label="软件评估" value="1"></el-option>
                  <el-option label="文件审查" value="2"></el-option>
                  <el-option label="现场检查" value="3"></el-option>
                  <el-option label="性能检查" value="4"></el-option>
                  <el-option label="文件审查；现场检查" value="5"></el-option>
                  <el-option label="文件审查；性能检测" value="6"></el-option>
                  <el-option
                    label="文件审查；现场检查；性能检测"
                    value="7"
                  ></el-option>
                  <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_indoorTem"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                设计新风量&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.windNum"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_windNum == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_windNum == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_windNum == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_windNum == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_windNum == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_windNum == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_windNum == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_windNum"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                集中供暖系统热水循环水泵的耗电输热比
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.focusRatio"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_focusRatio == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_focusRatio == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_focusRatio == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_focusRatio == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_focusRatio == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_focusRatio == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_focusRatio == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_focusRatio"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                风机单位风量耗功率
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.windPower"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_windPower == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_windPower == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_windPower == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_windPower == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_windPower == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_windPower == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_windPower == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_windPower"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                空调水系统耗电输热比
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.airRatio"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_airRatio == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_airRatio == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_airRatio == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_airRatio == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_airRatio == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_airRatio == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_airRatio == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_airRatio"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                室温调节设施
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.indoorFacil"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_indoorFacil == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_indoorFacil == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_indoorFacil == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_indoorFacil == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_indoorFacil == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_indoorFacil == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_indoorFacil == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_indoorFacil"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="3" scope="row" style="background-color: #a3c1dd2d">
                计量装置
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;集中供暖系统</td>
              <td colspan="4">
                <textarea
                  v-model="formtable.facusSystem"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_facusSystem == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_facusSystem == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_facusSystem == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_facusSystem == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_facusSystem == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_facusSystem == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_facusSystem == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_facusSystem"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                区域冷/热源&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.regionSource"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_regionSource == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_regionSource == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_regionSource == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_regionSource == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_regionSource == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_regionSource == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_regionSource == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_regionSource"
                  type="text"
                  :disabled="isView"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                制冷站空调系统&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.coldSystem"
                  type="text"
                  placeholder="——"
                  :disabled="isView"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_coldSystem == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_coldSystem == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSystem == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSystem == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSystem == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldSystem == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_coldSystem == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_coldSystem"
                  type="text"
                  :disabled="isView"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                水力平衡
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.waterBalence"
                  type="text"
                  :disabled="isView"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_waterBalence == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_waterBalence == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_waterBalence == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_waterBalence == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_waterBalence == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_waterBalence == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_waterBalence == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_waterBalence"
                  type="text"
                  :disabled="isView"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                监测和控制系统
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.detectSystem"
                  type="text"
                  :disabled="isView"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_detectSystem == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_detectSystem == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_detectSystem == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_detectSystem == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_detectSystem == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_detectSystem == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_detectSystem == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_detectSystem"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="3" scope="row" style="background-color: #a3c1dd2d">
                照明系统
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp;照明功率密度
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.lightDensity"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_lightDensity == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_lightDensity == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_lightDensity == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightDensity == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightDensity == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightDensity == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_lightDensity == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_lightDensity"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                照度的时序自动控制装置
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.lightAuto"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_lightAuto == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_lightAuto == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_lightAuto == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightAuto == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightAuto == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightAuto == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_lightAuto == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_lightAuto"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;降低照度控制措施
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.lowerlight"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_lowerlight == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_lowerlight == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_lowerlight == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lowerlight == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_lowerlight == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lowerlight == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_lowerlight == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_lowerlight"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2" scope="row" style="background-color: #a3c1dd2d">
                生活热水系统
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp;热泵性能
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.heatPump"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_heatPump == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_heatPump == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_heatPump == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_heatPump == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_heatPump == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_heatPump == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_heatPump == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_heatPump"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                热水监控&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.hotMonitor"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotMonitor == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotMonitor == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotMonitor == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotMonitor == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotMonitor == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotMonitor == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotMonitor == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_hotMonitor"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <!-- <tr>
                <th scope="col" colspan="8" style="height:40px">&nbsp;测评内容</th>
                <th scope="col">&nbsp;测评方法</th>
                <th scope="col">&nbsp;测评结果</th>
              </tr> -->
            <tr>
              <th rowspan="19" scope="row">选择项&nbsp;</th>
              <td rowspan="5" style="background-color: #a3c1dd2d">
                &nbsp;可再生能源
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;太阳能热水系统</td>
              <td style="background-color: #a3c1dd2d">集热效率&nbsp;</td>
              <td colspan="4">
                <textarea
                  v-model="formtable.gatherHot"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_gatherHot == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_gatherHot == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_gatherHot == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_gatherHot == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_gatherHot == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_gatherHot == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_gatherHot == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_gatherHot"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                可再生能源发电系统&nbsp;
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea
                  v-model="formtable.renewRatio"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_renewRatio == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_renewRatio == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_renewRatio == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_renewRatio == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_renewRatio == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_renewRatio == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_renewRatio == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_renewRatio"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                太阳能光伏系统&nbsp;
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;光电转换效率</td>
              <td colspan="4">
                <textarea
                  v-model="formtable.lightEffic"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_lightEffic == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_lightEffic == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_lightEffic == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightEffic == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightEffic == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightEffic == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_lightEffic == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_lightEffic"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                &nbsp;地源热泵系统
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.earthRatio"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_earthRatio == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_earthRatio == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_earthRatio == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_earthRatio == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_earthRatio == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_earthRatio == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_earthRatio == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_earthRatio"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                空气源热泵&nbsp;
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea
                  v-model="formtable.airPump"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_airPump == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_airPump == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_airPump == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_airPump == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_airPump == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_airPump == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_airPump == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_airPump"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;自然通风
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.natureWind"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_natureWind == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_natureWind == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_natureWind == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_natureWind == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_natureWind == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_natureWind == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_natureWind == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_natureWind"
                  type="text"
                  :disabled="isView"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;自然采光
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.natureLight"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_natureLight == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_natureLight == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_natureLight == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_natureLight == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_natureLight == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_natureLight == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_natureLight == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_natureLight"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;遮阳措施
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.lightMeasure"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_lightMeasure == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_lightMeasure == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_lightMeasure == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightMeasure == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightMeasure == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_lightMeasure == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_lightMeasure == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_lightMeasure"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;能量回放
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.powerBack"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_powerBack == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_powerBack == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_powerBack == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_powerBack == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_powerBack == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_powerBack == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_powerBack == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_powerBack"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;蓄冷蓄热技术
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.saveSkill"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_saveSkill == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_saveSkill == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_saveSkill == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_saveSkill == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_saveSkill == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_saveSkill == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_saveSkill == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_saveSkill"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                冷凝热利用&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.coldHotUse"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_coldHotUse == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_coldHotUse == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_coldHotUse == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldHotUse == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldHotUse == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_coldHotUse == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_coldHotUse == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_coldHotUse"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;全新风/可变新风比
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.newWind"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_newWind == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_newWind == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_newWind == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_newWind == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_newWind == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_newWind == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_newWind == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_newWind"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;变流量/变风量
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.changeWind"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_changeWind == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_changeWind == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_changeWind == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_changeWind == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_changeWind == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_changeWind == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_changeWind == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_changeWind"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                供回水温差&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.changeTem"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_changeTem == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_changeTem == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_changeTem == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_changeTem == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_changeTem == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_changeTem == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_changeTem == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_changeTem"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;楼宇自控
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.buildControl"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_buildControl == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_buildControl == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_buildControl == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_buildControl == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_buildControl == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_buildControl == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_buildControl == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  v-model="formtable.evaInstitu_buildControl"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;计量统计+节能控制措施
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.energyControl"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_energyControl == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_energyControl == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_energyControl == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_energyControl == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_energyControl == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_energyControl == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_energyControl == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>

              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_energyControl"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                冷热源设备能效等级&nbsp;
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.energyGrade"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_energyGrade == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_energyGrade == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_energyGrade == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_energyGrade == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_energyGrade == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_energyGrade == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_energyGrade == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_energyGrade"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;风扇调风
              </td>
              <td colspan="4">
                <textarea
                  v-model="formtable.airCond"
                  :disabled="isView"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_airCond == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_airCond == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_airCond == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_airCond == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_airCond == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_airCond == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_airCond == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_airCond"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                &nbsp;其他新型节能措施
              </td>
              <td colspan="4">
                <textarea
                  :disabled="isView"
                  v-model="formtable.otherMeasure"
                  type="text"
                  placeholder="——"
                />
              </td>
              <td>
                <span v-if="formtable.evaluationWay_otherMeasure == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_otherMeasure == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_otherMeasure == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_otherMeasure == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_otherMeasure == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_otherMeasure == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_otherMeasure == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <textarea
                  :disabled="isView"
                  v-model="formtable.evaInstitu_otherMeasure"
                  type="text"
                  placeholder="——"
                />
              </td>
            </tr>
            <tr>
              <td colspan="10" scope="row">
                <div class="minyong">
                  <p>1、民用建筑能效测评结论：</p>
                  <p>
                    （1）经软件模拟该项目基础相对节能率为<input
                      v-model="formtable.relateEnergy"
                      type="text"
                      :disabled="isView"
                    />,(设计标准为
                    <span class="minyong_select">
                      <el-select
                        v-model="formtable.designStand"
                        :disabled="isView"
                      >
                        <el-option
                          label="《建筑节能与可再生能源利用通用规范》GB 55015-2021"
                          value="1"
                        ></el-option>
                        <el-option
                          label="《公共建筑节能设计标准》DGJ32J96-2010"
                          value="2"
                        ></el-option>
                        <el-option
                          label="《公共建筑节能设计标准》GB50189-2015"
                          value="3"
                        ></el-option>
                      </el-select>
                    </span>
                    <span> ); </span>
                  </p>
                  <p>
                    （2）经测评，该项目规定项<input
                      v-model="formtable.strip"
                      type="text"
                      :disabled="isView"
                    />条参评，均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020规定要求；
                  </p>
                  <p>
                    （3）经测评，该项目选择项<input
                      v-model="formtable.selectStrip"
                      type="text"
                      :disabled="isView"
                    />条参评，加分<input
                      v-model="formtable.grades"
                      type="text"
                      :disabled="isView"
                    />分；
                  </p>
                  <p>
                    （4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020标准要求，建筑节能率为<input
                      v-model="formtable.buildEnergyRatio"
                      :disabled="isView"
                      type="text"
                    />，测评合格。
                  </p>
                  <p>2、民用建筑能效标识建议：</p>
                  <p>
                    依据民用建筑能效测评结论，建议该建筑能效标识为
                    {{ project.stars }}星。
                  </p>
                </div>
              </td>
            </tr>
            <tr class="shence">
              <td colspan="4" scope="row">测评人员&nbsp;</td>
              <td colspan="4">&nbsp;审核人员</td>
              <td colspan="4">&nbsp;批准人员</td>
            </tr>
            <tr class="table_select shence">
              <td colspan="4">
                {{ formtable.evaluatUser }}
              </td>
              <td colspan="4">
                {{ formtable.auditUser }}
              </td>
              <td colspan="4">
                {{ formtable.approveUser }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getStaff } from "../../../../../api/user";
import {
  checkSave,
  editTableProject,
  projectTableInfo,
} from "../../../../../api/project";

export default {
  data() {
    return {
      projectHead: {},
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],
      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],
      jizu3: [],
      jizu4: [],
      jizu5: [],
      jizu6: [],
      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 2,
      staffPage2: 2,
      staffPage3: 2,
      activeStaff: 1,
      edit: true,
      //1是从形式审查调过来的，2是从技术审查跳过来的
      from: 1,
      shencha1: [],
       isView: false,
    };
  },

  mounted() {
    this.project_id = this.$route.query.project_id;
    this.getAllStaffList();
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.getInfo();
  },

  methods: {
    //保存项目
    saveProject() {
      let that = this;
      this.project.jizu = this.jizu;
      this.project.jizu2 = this.jizu2;
      this.project.jizu3 = this.jizu3;
      this.project.jizu4 = this.jizu4;
      this.project.detail = this.formtable;
      this.project.project = this.projectHead;
      let params = this.project;

      params.project.created_at = undefined;
      params.project.updated_at = undefined;
      params.project_id = this.id;
      //保存不改变状态
      params.save = 4;

      params.token = this.$store.state.token;
      if (params.files) {
        for (let i = 0; i < params.files.length; i++) {
          params.files[i].name = params.files[i].file;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$message.success("保存成功");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获得员工信息
    getStaffList(val = "") {
      console.log(this.activeStaff);
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 1000,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
        }
      });
    },
    check() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        if (that.project.status > 8) {
          that.edit = false;
        }

        let projectHead = {
          name: that.project.name,
          building_area: that.project.building_area,
          build_company: that.project.build_company,
          address: that.project.address,
          weather: that.project.weather,
          design_company: that.project.design_company,
          manger_company: that.project.manger_company,
          work_build: that.project.work_build,
        };
        that.projectHead = projectHead;

        let checks = res.data.checks;
        that.shencha1 = checks
          .map((item) => {
            if (item.type == 2) {
              return item;
            }
          })
          .filter((d) => d);
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 2) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }

        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        if (
          this.project.status != 1 &&
          this.project.status != 15 &&
          this.project.status != 17 &&
          this.project.status != 21
        ) {
          that.$nextTick(function () {
            let table = document.getElementById("engerytable");
            let inputs = table.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = true;
            }
            this.isView = true;
          });
        }
      });
    },

    pre_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/formDetail?project_id=" + this.project_id,
        });
      });
    },
    next_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/reportTable?project_id=" + this.project_id,
        });
      });
    },
    getAllStaffList() {
      let params = {
        token: this.$store.state.token,
        limit: 1000,
        page: 1,
      };
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          this.staffList = res.data;
          this.staffList2 = res.data;
          this.staffList3 = res.data;
        }
      });
    },
    /**
     * 筛选企业
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getStaffList(searchVlaue);
    },
    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },
  },
};
</script>
<style scoped>
th {
  background-color: #a3c1dd2d;
}

textarea::-webkit-textarea-placeholder {
  text-align: center;
}
/deep/ .el-textarea > textarea {
  height: 513px;
}

/deep/ .table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: 2px;
}
</style>
<style scoped>
textarea {
  width: 98%;
  height: 40px;
  border: none;
  text-align: center;
  line-height: 40px;
  overflow: visible;
  font-size: 14px;
  word-break: break-all;
  background-color: #f4f4f4f4;
}

textarea::-webkit-scrollbar {
  display: none;
}

.engerytable > table > tbody > .shence {
  text-align: center;
}

.engerytable > table > tbody > tr > td:nth-child(4) {
  text-align: center;
}

.engerytable > table > tbody > tr > td:nth-child(6) {
  text-align: center;
}

.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}
</style>
